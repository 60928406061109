import { css } from '@mui/material';
import { memo } from 'react';
import { useChartPositionStore } from '~/modules/cons-misc/mode-buttons/ChartPositionButton';
import { useZScorePick } from '~/modules/investment-consultant/stockPickTable/stockPickTable';
import { SimpleQuoteListInteractWithChart, WatchListWithChart } from '~/modules/symbolQuote/simple';
import FuiButton from '~/pages/heineken_template/components/FuiButton';
import { store } from '~/pages/heineken_template/_private/store';
import { weng888Stock_agentStore } from '~/pages/weng888_stock/_private/weng888Stock_agentStore';
import styleds from '~/pages/weng888_stock/_private/styleds';
import dayAPI from '~/utils/dayAPI';
import Fr_SimpleTopDown from '~/pages/heineken_template/_fr/fr_SimpleTopDown';
import { fill_vertical_cross_center } from '~/modules/AppLayout/FlexGridCss';
import { useSnapshot } from 'valtio';
import { weng888Stock_bandTrade, weng888Stock_dayTrade, weng888Stock_strategiesGroup, } from './weng888Stock_initStrategies';
import FuiSwitch from '~/pages/heineken_template/components/FuiSwitch';
import useMedia from '~/hooks/useMedia';
import { useVirtualAccountHolding, useVirtualTransaction, } from '~/modules/virtualExchange/useVirtualExchangeResource';
import { useIntradayPick } from '~/modules/screener/useDatePick';
import { useProxy } from 'valtio/react/utils';
import { TransactionSymbolList } from '~/modules/page_modules/cons_modules/SymbolQuote/TransactionSymbolQuote';
import { usePercentRangeResource } from '~/modules/screener/containers/useStockScreenerResource';
import { weng888_gapTrade } from '~/trades/indicators/weng888/weng888_gapTrade';
export const Weng888Stock_Sidebar1 = memo(function Sidebar1(props) {
    const state = useSnapshot(weng888Stock_agentStore);
    const { isPc } = useMedia();
    return (<styleds.Sidebar>
      {!isPc && (<FuiSwitch.Display leftText='高價股模式' rightText='低價股模式' checked={state.stockPriceLowMode} onChange={checked => {
                weng888Stock_agentStore.stockPriceLowMode = checked;
            }}/>)}
      <ButtonGroup />
      {!state.tabs.store.active?.includes('訊號') && <StrategyGroup />}
      <styleds.ModeContent>
        <weng888Stock_agentStore.tabs.WithContent for='智能選股'>
          <DefaultPage />
        </weng888Stock_agentStore.tabs.WithContent>

        <weng888Stock_agentStore.tabs.WithContent for='自選股'>
          <WatchListPage />
        </weng888Stock_agentStore.tabs.WithContent>

        <weng888Stock_agentStore.tabs.WithContent for='當日訊號'>
          <RealtimePage />
        </weng888Stock_agentStore.tabs.WithContent>

        <weng888Stock_agentStore.tabs.WithContent for='當沖訊號'>
          <DayTradeRealtimePage />
        </weng888Stock_agentStore.tabs.WithContent>

        <weng888Stock_agentStore.tabs.WithContent for='隔日訊號'>
          <NewHighPage />
        </weng888Stock_agentStore.tabs.WithContent>
      </styleds.ModeContent>
    </styleds.Sidebar>);
});
const ButtonGroup = memo(function ButtonGroup() {
    useProxy(weng888Stock_agentStore);
    return (<styleds.ButtonContent>
      <weng888Stock_agentStore.tabs.WithButton to='智能選股'>
        <FuiButton.Display>智能選股</FuiButton.Display>
      </weng888Stock_agentStore.tabs.WithButton>

      <weng888Stock_agentStore.tabs.WithButton to='自選股'>
        <FuiButton.Display>自選股</FuiButton.Display>
      </weng888Stock_agentStore.tabs.WithButton>

      <weng888Stock_agentStore.tabs.WithButton to='當日訊號'>
        <FuiButton.Display>當日訊號</FuiButton.Display>
      </weng888Stock_agentStore.tabs.WithButton>

      <weng888Stock_agentStore.tabs.WithButton to='當沖訊號'>
        <FuiButton.Display>當沖訊號</FuiButton.Display>
      </weng888Stock_agentStore.tabs.WithButton>

      <weng888Stock_agentStore.tabs.WithButton to='隔日訊號'>
        <FuiButton.Display>隔日訊號</FuiButton.Display>
      </weng888Stock_agentStore.tabs.WithButton>
    </styleds.ButtonContent>);
});
const StrategyGroup = memo(function StrategyGroup() {
    const charting = useSnapshot(store.charting);
    const state = useSnapshot(weng888Stock_agentStore);
    return (<styleds.ButtonContent>
      {weng888Stock_strategiesGroup.main.map(config => {
            const isActive = charting.strategySelected.displayName === config.displayName &&
                state.isHoldingSymbol === false;
            return (<FuiButton.Display key={config.displayName} active={isActive} onClick={event => {
                    store.charting.setStrategy(config);
                    store.charting.updateFromStrategyState();
                    weng888Stock_agentStore.isHoldingSymbol = false;
                }}>
            {config.displayName}
          </FuiButton.Display>);
        })}
    </styleds.ButtonContent>);
});
const DefaultPage = memo(function DefaultPage() {
    const state = useSnapshot(weng888Stock_agentStore);
    const charting = useSnapshot(store.charting);
    const indicators = () => {
        if (charting.strategySelected.displayName.includes('市場熱度')) {
            return weng888Stock_bandTrade;
        }
        if (charting.strategySelected.displayName === '當沖') {
            return weng888Stock_dayTrade;
        }
    };
    const interval = () => {
        if (charting.strategySelected.displayName === '市場熱度(日)') {
            return '1D';
        }
        else if (charting.strategySelected.displayName === '市場熱度(60)') {
            return '60';
        }
        else if (charting.strategySelected.displayName === '當沖') {
            return '3';
        }
        else
            return '1D';
    };
    const bandTradeLongSymbol = useZScorePick({
        date: state.stockPickedDate.formatBy('-'),
        days: 20,
        type: 'strong',
        volume: 1e9,
        amountGte: 1000000000,
        minprice: state.stockPriceLowMode === true ? 0 : 100,
        maxprice: state.stockPriceLowMode === true ? 100 : 9999,
        itemLimit: 10,
    }).resultSymbol;
    const bandTradeShortSymbol = useZScorePick({
        date: state.stockPickedDate.formatBy('-'),
        days: 10,
        type: 'weak',
        volume: 1e9,
        amountGte: 1000000000,
        minprice: state.stockPriceLowMode === true ? 0 : 100,
        maxprice: state.stockPriceLowMode === true ? 100 : 9999,
        itemLimit: 10,
    }).resultSymbol;
    return (<div css={css `
        ${fill_vertical_cross_center};
        gap: 4px;
      `}>
      <styleds.DataPickerContnet>
        <styleds.DataPickerItem>選股日期</styleds.DataPickerItem>
        <styleds.DataPickerItem>
          <weng888Stock_agentStore.stockPickedDate.DatePickerOfMui minDate={dayAPI().subtract(20, 'day')}/>
        </styleds.DataPickerItem>
      </styleds.DataPickerContnet>

      <div css={css `
          height: calc(100% - 32px);
          width: 100%;
        `}>
        <Fr_SimpleTopDown.Display top={<styleds.symbolGroupContnet variant='long'>
              <styleds.Title variant='long'>多頭強勢</styleds.Title>
              <styleds.SymbolList>
                <SimpleQuoteListInteractWithChart data={bandTradeLongSymbol ?? []} chart={store.charting} handleClick={() => {
                store.charting.changeInterval(interval());
                useChartPositionStore.positionType = 'long';
                weng888Stock_agentStore.isHoldingSymbol = false;
            }} indicators={indicators()}/>
              </styleds.SymbolList>
            </styleds.symbolGroupContnet>} down={<styleds.symbolGroupContnet variant='short'>
              <styleds.Title variant='short'>空頭強勢</styleds.Title>
              <styleds.SymbolList>
                <SimpleQuoteListInteractWithChart data={bandTradeShortSymbol ?? []} chart={store.charting} handleClick={() => {
                store.charting.changeInterval(interval());
                useChartPositionStore.positionType = 'short';
                weng888Stock_agentStore.isHoldingSymbol = false;
            }} indicators={indicators()}/>
              </styleds.SymbolList>
            </styleds.symbolGroupContnet>}/>
      </div>
    </div>);
});
const WatchListPage = memo(function WatchListPage() {
    const charting = useSnapshot(store.charting);
    const indicators = () => {
        if (charting.strategySelected.displayName === '市場熱度') {
            return weng888Stock_bandTrade;
        }
        if (charting.strategySelected.displayName === '當沖') {
            return weng888Stock_dayTrade;
        }
    };
    const interval = () => {
        if (charting.strategySelected.displayName === '市場熱度(日)') {
            return '1D';
        }
        else if (charting.strategySelected.displayName === '市場熱度(60)') {
            return '60';
        }
        else if (charting.strategySelected.displayName === '當沖') {
            return '3';
        }
        else
            return '1D';
    };
    return (<Fr_SimpleTopDown.Display top={<styleds.symbolGroupContnet variant='long'>
          <styleds.Title variant='long'>多方自選股</styleds.Title>
          <styleds.SymbolList>
            <WatchListWithChart groupName={'weng888_stock_group_1'} useChart={store.charting} handleClick={() => {
                store.charting.changeInterval(interval()),
                    weng888Stock_agentStore.isHoldingSymbol === false;
            }} indicators={indicators()}/>
          </styleds.SymbolList>
        </styleds.symbolGroupContnet>} down={<styleds.symbolGroupContnet variant='short'>
          <styleds.Title variant='short'>空方自選股</styleds.Title>
          <styleds.SymbolList>
            <WatchListWithChart groupName={'weng888_stock_group_2'} useChart={store.charting} handleClick={() => {
                store.charting.changeInterval(interval()),
                    weng888Stock_agentStore.isHoldingSymbol === false;
            }} indicators={indicators()}/>
          </styleds.SymbolList>
        </styleds.symbolGroupContnet>}/>);
});
const RealtimePage = memo(function RealtimePage() {
    const intraDay = useIntradayPick();
    const dataLong = useVirtualTransaction('weng888_stock_long_2', {
        beginDatetime: intraDay,
        endDatetime: intraDay,
    })
        ?.filter(datum => datum.bs === 'B' && datum.positionType === 'O')
        ?.map(datum => datum.symbol) || [];
    const dataShort = useVirtualTransaction('weng888_stock_short_2', {
        beginDatetime: intraDay,
        endDatetime: intraDay,
    })
        ?.filter(datum => datum.bs === 'S' && datum.positionType === 'O')
        ?.map(datum => datum.symbol) || [];
    return (<div css={css `
        height: calc(100% - 0px);
        width: 100%;
      `}>
      <Fr_SimpleTopDown.Display top={<styleds.symbolGroupContnet variant='long'>
            <styleds.Title variant='long'>市場熱度多頭訊號</styleds.Title>
            <styleds.SymbolList>
              <SimpleQuoteListInteractWithChart data={dataLong ?? []} chart={store.charting} handleClick={() => {
                store.charting.changeInterval('D');
                useChartPositionStore.positionType = 'long';
                weng888Stock_agentStore.isHoldingSymbol = false;
            }} indicators={weng888Stock_bandTrade}/>
            </styleds.SymbolList>
          </styleds.symbolGroupContnet>} down={<styleds.symbolGroupContnet variant='short'>
            <styleds.Title variant='short'>市場熱度空頭訊號</styleds.Title>
            <styleds.SymbolList>
              <SimpleQuoteListInteractWithChart data={dataShort ?? []} chart={store.charting} handleClick={() => {
                store.charting.changeInterval('D');
                useChartPositionStore.positionType = 'short';
                weng888Stock_agentStore.isHoldingSymbol = false;
            }} indicators={weng888Stock_bandTrade}/>
            </styleds.SymbolList>
          </styleds.symbolGroupContnet>}/>
    </div>);
});
const DayTradeRealtimePage = memo(function DayTradeRealtimePage() {
    const intraDay = useIntradayPick();
    const realtimeLongHolding_ = useVirtualAccountHolding('weng888_daytrade_long');
    const realtimeLongHolding = realtimeLongHolding_?.map(s => s.symbol);
    const realtimeShortHolding_ = useVirtualAccountHolding('weng888_daytrade_short');
    const realtimeShortHolding = realtimeShortHolding_?.map(s => s.symbol);
    const dataLong = useVirtualTransaction('weng888_daytrade_long', {
        beginDatetime: intraDay,
        endDatetime: intraDay,
    })
        ?.filter(datum => datum.bs === 'B' && datum.positionType === 'O')
        ?.map(datum => datum.symbol) || [];
    const dataShort = useVirtualTransaction('weng888_daytrade_short', {
        beginDatetime: intraDay,
        endDatetime: intraDay,
    })
        ?.filter(datum => datum.bs === 'S' && datum.positionType === 'O')
        ?.map(datum => datum.symbol) || [];
    const long = [...new Set(dataLong)];
    const short = [...new Set(dataShort)];
    return (<div css={css `
        height: calc(100% - 0px);
        width: 100%;
      `}>
      <Fr_SimpleTopDown.Display top={<styleds.symbolGroupContnet variant='long'>
            <styleds.Title variant='long'>當沖即時多頭訊號</styleds.Title>
            <styleds.SymbolList>
              <TransactionSymbolList data={realtimeLongHolding ?? []} bs={'B'} chart={store.charting} indicators={weng888Stock_dayTrade} handleClick={() => {
                store.charting.changeInterval('3');
                useChartPositionStore.positionType = 'short';
                weng888Stock_agentStore.isHoldingSymbol = false;
            }}/>
            </styleds.SymbolList>
          </styleds.symbolGroupContnet>} down={<styleds.symbolGroupContnet variant='short'>
            <styleds.Title variant='short'>當沖即時空頭訊號</styleds.Title>
            <styleds.SymbolList>
              <TransactionSymbolList data={realtimeShortHolding ?? []} bs={'S'} chart={store.charting} indicators={weng888Stock_dayTrade} handleClick={() => {
                store.charting.changeInterval('3');
                useChartPositionStore.positionType = 'short';
                weng888Stock_agentStore.isHoldingSymbol = false;
            }}/>
            </styleds.SymbolList>
          </styleds.symbolGroupContnet>}/>
    </div>);
});
const NewHighPage = memo(function NewHighPage() {
    const dailyRankData = usePercentRangeResource({
        days: 20,
        status: 'new_high',
        volume_gte: 1000,
        volume_lte: 0,
    }).data;
    const filterData = dailyRankData?.filter(val => val.close / val.open > 1.05);
    const symbols = filterData?.map(s => s.symbol);
    return (<div css={css `
        height: calc(100% - 0px);
        width: 100%;
      `}>
      <styleds.SymbolList>
        <SimpleQuoteListInteractWithChart data={symbols ?? []} chart={store.charting} handleClick={() => {
            store.charting.changeInterval('D');
            useChartPositionStore.positionType = 'long';
            weng888Stock_agentStore.isHoldingSymbol = false;
        }} indicators={[weng888_gapTrade]}/>
      </styleds.SymbolList>
    </div>);
});
