import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { memo } from 'react-tracked';
import useMedia from '~/hooks/useMedia';
import { fill_horizontal_all_center, fill_vertical_all_center, fill_vertical_main_center, jc, pureGrid, } from '~/modules/AppLayout/FlexGridCss';
import { GoogleLoginComponent } from '~/modules/investment-consultant/loginPage/GoogleLoginComponent';
import { fr_me } from '../heineken_template/_fr/fr_me';
import { useSnapshot } from 'valtio';
import { scrollbar2Css } from '~/css/scrollbarCss';
const LogoLink = memo(function LogoLink(props) {
    return (<styleds.link.item href={props.link} target='_blank' rel='noreferrer'>
      <styleds.link.logo src={props.logoSrc}/>
      <span>{props.children}</span>
    </styleds.link.item>);
});
/** 尚未使用Google帳號登入 */
const UnLoginView = memo(function UnLoginView() {
    return (<div>
      <GoogleLoginComponent />
    </div>);
});
/** 尚未使用Google帳號登入 */
const UnregisteredView = memo(function UnregisteredView() {
    const me = useSnapshot(fr_me).me;
    return (<styleds.register.container>
      <styleds.register.info>
        <styleds.register.item>
          <styleds.register.title>帳戶名稱</styleds.register.title>
          <span>{me.name}</span>
        </styleds.register.item>

        <styleds.register.item>
          <styleds.register.title>會員代碼</styleds.register.title>
          <span>{me.code}</span>
        </styleds.register.item>

        <styleds.register.item>
          <styleds.register.title>帳戶信箱</styleds.register.title>
          <span>{me.email}</span>
        </styleds.register.item>
      </styleds.register.info>

      <styleds.register.warring>
        <span>權限尚未開通</span>
        <span>聯繫客服專員</span>
      </styleds.register.warring>

      <styleds.register.logout onClick={() => {
            fr_me.logoutWithFirebase();
        }}>
        登出
      </styleds.register.logout>
    </styleds.register.container>);
});
export const Edwin_LoginView = memo(function Edwin_LoginView() {
    const { isPc, isPhone } = useMedia();
    fr_me.useInstall();
    const me = useSnapshot(fr_me).me;
    return (<styleds.container>
      <styleds.demoContent css={css `
          display: ${isPhone ? 'none' : ''};
        `}>
        <img css={css `
            width: 300px;
          `} src='/edwin/investment-rm-bg.png'/>
      </styleds.demoContent>
      <styleds.sidebar.container css={css `
          width: ${!isPhone ? '500px' : '100%'};
        `}>
        <styleds.sidebar.logo>
          <img css={css `
              width: 164px;
            `} src='/edwin/favicon.png'/>
        </styleds.sidebar.logo>

        <styleds.sidebar.titleContent>
          <styleds.sidebar.mainTitle>愛德恩</styleds.sidebar.mainTitle>
          <styleds.sidebar.subTitle>動能強勢選股</styleds.sidebar.subTitle>
        </styleds.sidebar.titleContent>

        <div css={css `
            ${fill_vertical_all_center};
          `}>
          {!me.uid && <UnLoginView />}
          {me.uid && <UnregisteredView />}
        </div>

        <styleds.sidebar.linkContent>
          <LogoLink link={'https://www.instagram.com/kids_investment_/'} logoSrc={'https://png.pngtree.com/png-vector/20221018/ourmid/pngtree-instagram-icon-png-image_6315974.png'}>
            小朋友學投資
          </LogoLink>
          <LogoLink link={'https://www.facebook.com/edwin.investment'} logoSrc={'https://upload.wikimedia.org/wikipedia/commons/thumb/6/6c/Facebook_Logo_2023.png/768px-Facebook_Logo_2023.png'}>
            愛德恩_小朋友也能學好投資
          </LogoLink>
        </styleds.sidebar.linkContent>
        <styleds.sidebar.copyright>
          Copyright © 2023 kidsinvestment. All rights reserved.
        </styleds.sidebar.copyright>
      </styleds.sidebar.container>
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_horizontal_all_center};
    position: fixed;
    z-index: 1300;
    background-color: #f1f1f1;
  `,
    sidebar: {
        container: styled.div `
      ${scrollbar2Css};
      ${fill_vertical_all_center};
      ${pureGrid};
      grid-template-rows: 216px 116px 1fr 40px 40px;
      grid-template-columns: 100%;
      background-color: #161b29;
      padding: 16px;
      font-weight: 900;
      background-image: linear-gradient(180deg, #161b29dd, #161b29dd, #161b29ff),
        url('https://static.vecteezy.com/system/resources/previews/008/505/869/original/candlestick-graph-bar-design-stock-market-business-concept-png.png');
      background-size: cover;
      background-position: bottom;
    `,
        logo: styled.div `
      ${fill_vertical_all_center};
    `,
        titleContent: styled.div `
      ${fill_vertical_all_center};
    `,
        mainTitle: styled.div `
      ${fill_vertical_all_center};
      color: #f2ac3d;
      font-size: 44px;
      font-weight: bold;
      padding-right: 32%;
    `,
        subTitle: styled.div `
      ${fill_vertical_all_center};
      color: #fff;
      font-size: 36px;
      font-weight: bold;
    `,
        linkContent: styled.div `
      ${fill_horizontal_all_center};
      ${jc.spaceAround};
    `,
        copyright: styled.div `
      ${fill_horizontal_all_center};
      font-size: 12px;
      font-weight: 400;
    `,
    },
    register: {
        container: styled.div `
      ${fill_vertical_all_center};
      gap: 16px;
      padding: 24px;
    `,
        item: styled.div `
      ${fill_vertical_all_center};
      height: 52px;
      gap: 2px;
    `,
        title: styled.div `
      ${fill_horizontal_all_center};
      width: 108px;
      height: 24px;
      background-color: #46596a; //#f3b23e;
      border-radius: 4px;
      font-size: 14px;
    `,
        info: styled.div `
      ${fill_vertical_all_center};
      height: 188px;
      gap: 12px;
    `,
        warring: styled.div `
      ${fill_vertical_all_center};
      width: 300px;
      height: 64px;
      font-size: 14px;
      background-color: #aa333399;
      border-radius: 6px;
    `,
        logout: styled.div `
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    `,
    },
    demoContent: styled.div `
    ${fill_vertical_main_center};
    background-color: #1f262e;
    width: calc(100% - 500px);
    background-image: linear-gradient(90deg, #1f262e, #1f262ecc, #1f262e88),
      url('https://scontent.frmq3-2.fna.fbcdn.net/v/t39.30808-6/315226904_597606635454656_392760390531705972_n.jpg?_nc_cat=101&ccb=1-7&_nc_sid=5f2048&_nc_ohc=6Bh8hx5h7WMAX9xs3xi&_nc_ht=scontent.frmq3-2.fna&oh=00_AfA3NhlQ1MqzPyd1s7Z15-xzMT74jSahhawGb4NarQk4hQ&oe=653D1051');
    background-position: center;
    background-size: cover;
    font-weight: 900;
    font-size: 48px;
    padding: 32px;
  `,
    link: {
        item: styled.a `
      ${fill_horizontal_all_center};
      width: auto;
      height: 24px;
      color: #fff;
      font-size: 14px;
      gap: 4px;
    `,
        logo: styled.img `
      width: 24px;
      height: 24px;
    `,
    },
};
