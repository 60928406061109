import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { memo } from 'react';
import useMedia from '~/hooks/useMedia';
import { fill_horizontal_all_center, fill_vertical_all_center, jc, } from '~/modules/AppLayout/FlexGridCss';
export const Edwin_Footer = memo(function Edwin_Footer() {
    const { isPc, isPhone } = useMedia();
    return (<styleds.container>
      <styleds.warring css={css `
          padding: ${isPhone ? '8px' : '32px'};
        `}>
        本資料僅供教學參考使用，不得作為任何金融商品推介買賣之依據，亦不得作為向他人提出投資建議使用。
        本站僅就資料提供適當意見與資訊，但不保證資料完整與正確性，如有遺漏或偏頗之處，請瀏覽人士，自行承擔一切風險，本站不負擔盈虧之法律責任。
        其中所出現之標的僅作為舉例說明使用，並無意圖向任何人推薦投資標的。
      </styleds.warring>
      <styleds.copyright>Copyright © 2023 kidsinvestment. All rights reserved.</styleds.copyright>
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_vertical_all_center};
    ${jc.spaceBetween};
    background-color: #252832;
  `,
    warring: styled.span `
    ${fill_horizontal_all_center};
    font-size: 13px;
  `,
    copyright: styled.span `
    ${fill_horizontal_all_center};
    height: 32px;
    font-size: 12px;
  `,
};
