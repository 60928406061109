import { store } from '~/pages/heineken_template/_private/store';
import { more888_analyzer } from '~/trades/indicators/more888/more888_analyzer';
import { more888_bandDc } from '~/trades/indicators/more888/more888_bandDc';
import { more888_dayPower } from '~/trades/indicators/more888/more888_dayPower';
import { more888_dayTrade } from '~/trades/indicators/more888/more888_dayTrade';
import { more888_dc } from '~/trades/indicators/more888/more888_dc';
import { more888_priceLine } from '~/trades/indicators/more888/more888_priceLine';
import { more888_trendTrade } from '~/trades/indicators/more888/more888_trendTrade';
export const more888_strategiesGroup = {
    /** 主要策略集 */
    main: [
        {
            displayName: '順勢當沖',
            indicators: [
                more888_dayTrade,
                more888_dc,
                more888_priceLine,
                more888_dayPower,
                more888_analyzer,
            ],
            symbol: 'TXAM-1',
            interval: '3',
            panesRatio: 60,
        },
        {
            displayName: '順勢波段',
            indicators: [
                more888_trendTrade,
                more888_dc,
                more888_bandDc,
                more888_priceLine,
                more888_dayPower,
                more888_analyzer,
            ],
            symbol: 'TX-1',
            interval: '5',
        },
    ],
};
export const more888_initStrategies = () => {
    store.charting.indicatorsPreloaded = [
        ...store.charting.indicatorsPreloaded,
        ...[
            more888_dayTrade,
            more888_trendTrade,
            more888_dc,
            more888_bandDc,
            more888_dayPower,
            more888_priceLine,
            more888_analyzer,
        ],
    ];
    store.charting.initStrategy({
        configs: [...more888_strategiesGroup.main],
    });
};
