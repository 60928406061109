import { memo } from 'react';
import { styleds } from './styleds';
import { store } from '~/pages/heineken_template/_private/store';
import FuiButton from '../heineken_template/components/FuiButton';
import { proxy, useSnapshot } from 'valtio';
import { more888Stock_strategiesGroup } from './more888Stock_initStrategies';
import { useStockPriceChangeDistributionState } from '../futuresai/stock-price-change-distribution/components/useStockPriceChangeDistributionState';
import { css } from '@emotion/react';
import SymbolRankingHorizontalBar from '~/modules/investment-consultant/symbolRankingChart/SymbolRankingHorizontalBar';
import { fill_horizontal_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { staticStore } from '../heineken_template/_private/staticStore';
import StockChangePercentGradeChart from '~/modules/investment-consultant/symbolRankingChart/StockChangePercentGradeChart';
import RiseFallBarGrade from '../futuresai/stock-price-change-distribution/components/RiseFallBarGrade';
import { Chip } from '@mantine/core';
export const more888StockStore = proxy({ datyType: 'volume' });
export const More888Stock_SidePane2 = memo(function More888Stock_SidePane2() {
    const state = useSnapshot(more888StockStore);
    const charting = useSnapshot(store.charting);
    const stockCategory = useSnapshot(staticStore);
    return (<styleds.SideBar>
      <styleds.SideBarTitle>策略選擇</styleds.SideBarTitle>
      <styleds.StrategyGroup>
        {more888Stock_strategiesGroup.main.map(config => {
            const isActive = config.displayName === charting.strategySelected.displayName;
            return (<FuiButton.Display key={config.displayName} active={isActive} onClick={() => {
                    store.charting.setStrategy(config);
                    store.charting.updateFromStrategyState();
                }}>
              {config.displayName}
            </FuiButton.Display>);
        })}
      </styleds.StrategyGroup>
      <div css={css `
          ${fill_horizontal_cross_center};
          height: 30px;
          gap: 4px;
          padding: 0 4px;
          border-radius: 4px;
        `}>
        <span>市場排行</span>
        <Chip checked={state.datyType === 'volume'} onClick={() => {
            more888StockStore.datyType = 'volume';
        }} radius={'md'} variant={'filled'}>
          成交值
        </Chip>
        <Chip checked={state.datyType === 'changePercent'} onClick={() => {
            more888StockStore.datyType = 'changePercent';
        }} radius={'md'} variant={'filled'}>
          漲跌幅
        </Chip>
      </div>
      <div css={css `
          width: 100%;
          height: calc(100% - 370px);
          background-color: #242732;
          border-radius: 5px;
        `}>
        <SymbolRankingHorizontalBar.Display symbols={stockCategory.symbol.stockCategory.map(s => s.index_symbol).slice(0, 25) ?? []} type={state.datyType} fontSize={15} nameLabelWidth={48} barSize={22}/>
      </div>
      <styleds.SideBarTitle>市場漲跌家數</styleds.SideBarTitle>
      <div css={css `
          width: 100%;
          height: 204px;
          background-color: #232733;
          border-radius: 5px;
          padding: 4px;
        `}>
        <useStockPriceChangeDistributionState.Provider>
          <DataChart />
        </useStockPriceChangeDistributionState.Provider>
      </div>
    </styleds.SideBar>);
});
//市場漲跌家數
export const DataChart = memo(function DataChart_() {
    const { state: { overallCount, fallCount, flatCount, riseCount, vBar }, } = useStockPriceChangeDistributionState.useContainer();
    // const up = (riseCount / overallCount) * 100
    // const dn = (fallCount / overallCount) * 100
    // const no = flatCount / overallCount
    return (<div css={css `
        height: 100%;
        width: 100%;
        font-size: 14px;
      `}>
      <div css={css `
          width: 100%;
          height: calc(100% - 56px);
        `}>
        <StockChangePercentGradeChart.display data={vBar}/>
      </div>
      <RiseFallBarGrade data={vBar}/>
    </div>);
});
